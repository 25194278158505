import { SenecaResponse, User, UserAcknowledges, NotificationQueue, BgAcademyIndicators, Engagement } from "atfcore-commonclasses";
import { IApplicationScope } from "../../shared/global/applicationScope";


module CommonServices {
    var app = angular.module("app");

    export interface ICommonServicesControllerScope extends IApplicationScope {
        logout: Function;

        notificationCount: number;
        notificationTimeout: any;
        //getNotificationList: Function;

        bgacademyIndicators: any;
        bgacademyIndicatorsTimeout: any;
        //getBgacademyIndicators: Function;

        goToHome: Function;
        goToSettings: Function;
        goToLogin: Function;
        goToHomeAdmin: Function;
        goToMonitoring: Function;
        isExpanced: boolean;
        expandMainHeader: Function;
        hamburgerMenu: string;
        isFabOpen: Boolean;
        openLeftMenu: Function;
        openDiaryMenu: Function;
        globalApplicationData: any;
        topDirections: any;
        bottomDirections: any;
        availableModes: any;
        selectedMode: string;
        availableDirections: any;
        selectedDirection: string;
        canAccessToApplication: boolean;
        checkIfHasAccount: Function;
        hasNotApplicationEnabled: Function;
        getExternalResourceUrl: Function;
        getExternalResourceName: Function;
        getDownloadTempFileUrl: Function;
        getDownloadPersistentFile: Function;
        getDownloadRendiconto2019File: Function;
        getAvatarUrl: Function;
        getDateFromSessionStorage: Function;
        generateUUID: Function;
    }

    export interface ICommonServicesStateParams extends angular.ui.IStateParamsService {
        userId: string;
    }

    export class CommonServicesController {
        static $inject = ["$scope", "$state", "$stateParams", "BgacademyApplicationData", "$rootScope", "GlobalApplicationData", "UserService", "NotificationService", "AnagService", "UtilService", "$timeout", "toaster", "$mdSidenav", "$mdDialog", "$translate"];

        constructor(protected $scope: CommonServices.ICommonServicesControllerScope,
            protected $state: angular.ui.IStateService,
            protected $stateParams: ICommonServicesStateParams,
            protected BgacademyApplicationData: any,
            protected $rootScope: angular.IRootScopeService,
            protected GlobalApplicationData: any,
            protected UserService: any,
            protected NotificationService: any,
            protected AnagService: any,
            protected UtilService: any,
            protected $timeout: any,
            protected toaster: any,
            protected $mdSidenav: any,
            protected $mdDialog: any,
            protected $translate: angular.translate.ITranslateService
        ) {
            let _self = this;

            //           $scope.bgacademyIndicators = {};
            // Controllo se l'utente ha un account nell'applicazione
            $scope.canAccessToApplication = false;
            $scope.checkIfHasAccount = this.checkIfHasAccount;
            $scope.checkIfHasAccount();

            // Icona Hamburger Menu
            $scope.hamburgerMenu = 'menu';

            // Esegue il logout e porta nella pagina di login
            $scope.logout = this.logout;

            // Controllo se ho il token (mi serve per switchare l'header)
            $scope.globalApplicationData = GlobalApplicationData;

            // Disabilita i pulsanti nell'header (link alla Library, CM..) in base alle abilitazioni dell'utente
            $scope.hasNotApplicationEnabled = this.hasNotApplicationEnabled;

            $scope.isFabOpen = false;

            // Gestisce l'espansione dell'header
            $scope.isExpanced = false;
            $scope.expandMainHeader = this.expandMainHeader;

            //Tool fab
            $scope.topDirections = ['left', 'up'];
            $scope.bottomDirections = ['down', 'right'];

            $scope.availableModes = ['md-fling', 'md-scale'];
            $scope.selectedMode = 'md-fling';

            $scope.availableDirections = ['up', 'down', 'left', 'right'];
            $scope.selectedDirection = 'down';

            // Porta nel Login
            $scope.goToLogin = this.goToLogin;

            // Porta alla home
            $scope.goToHome = this.goToHome;

            // Porta alla home admin
            $scope.goToHomeAdmin = this.goToHomeAdmin;

            // Porta alla home admin
            $scope.goToMonitoring = this.goToMonitoring;


            // Porta alle Impostazioni
            $scope.goToSettings = this.goToSettings;

            // Metodo per wrappare le chiamate a risorse esterne
            $scope.getExternalResourceUrl = this.getExternalResourceUrl;
            $scope.getExternalResourceName = this.getExternalResourceName;

            // Metodo per download file temporanei dal server
            $scope.getDownloadTempFileUrl = this.getDownloadTempFileUrl;
            $scope.getDownloadPersistentFile = this.getDownloadPersistentFile;
            $scope.getDownloadRendiconto2019File = this.getDownloadRendiconto2019File;

            // Metodo per ottenere l'avatar
            $scope.getAvatarUrl = this.getAvatarUrl;

            $scope.getDateFromSessionStorage = this.getDateFromSessionStorage;

            $scope.generateUUID = this.generateUUID;

            //Recupera le notifiche
            //            this.getNotificationList(this);

            //Recupera i contatori dei banner
            //            this.getBgacademyIndicators(this);

            // Apertura Sidenav di sinistra
            $scope.openLeftMenu = this.openLeftMenu;

            // Apertura Sidenav del Diario
            $scope.openDiaryMenu = this.openDiaryMenu;

            let imagePath = 'assets/img/placeholder.png';

            //Tool fab
            $scope.topDirections = ['left', 'up'];
            $scope.bottomDirections = ['down', 'right'];


            $scope.availableModes = ['md-fling', 'md-scale'];
            $scope.selectedMode = 'md-fling';

            $scope.availableDirections = ['up', 'down', 'left', 'right'];
            $scope.selectedDirection = 'down';

            /* Mi metto in ascolto dell'evento che forza un refresh delle notifiche
            let cleanUpNotificationRefreshEvent = this.$rootScope.$on('notificationsRefresh', (event: any, args: any) => {
                _self.getNotificationList(_self);
            });

            let cleanUpBgacademyIndicatorsRefreshEvent = this.$rootScope.$on('bgacademyIndicatorsRefresh', (event: any, args: any) => {
                _self.getBgacademyIndicators(_self);
            }); 

            this.$scope.$on('$destroy', () => {
                cleanUpNotificationRefreshEvent();
                cleanUpBgacademyIndicatorsRefreshEvent();
            }); */

            this.$rootScope.$on('notificationClick', () => {
                if (this.$scope.isExpanced == true)
                    this.$scope.expandMainHeader();
            });
        }

        // Controllo se l'utente ha un account nell'applicazione
        public checkIfHasAccount = () => {
            if (this.$stateParams.userId) {
                // Chiamo il Server per verificare se ho già aperto e concluso il Tutorial   
                this.AnagService.getUserById.query({
                    userId: this.$stateParams.userId
                }).$promise
                    .then((data: SenecaResponse<User>) => {
                        // Se c'è segnalo l'errore
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Mi è tornato un account, quindi verifico che sia abilitato ad accedere al dp
                            this.AnagService.getApplicationsEnabled.query({
                                userId: this.$stateParams.userId
                            }).$promise
                                .then((applications: SenecaResponse<Array<String>>) => {
                                    // Se c'è segnalo l'errore
                                    if (applications.error) {
                                        // Dati non validi, quindi alzo l'errore
                                        this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                                    } else {
                                        for (let i = 0; i < applications.response.length; i++) {
                                            if (applications.response[i] == this.BgacademyApplicationData.constants.LIBRARY) {
                                                this.$scope.canAccessToApplication = true;
                                            }
                                        }
                                    }
                                })
                                .catch((error: Error) => {
                                    var errors: Array<any> = [];
                                    errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                                    this.$rootScope.$emit("showApplicationModalErrors", errors);
                                });
                        }
                    })
                    .catch((error: Error) => {
                        var errors: Array<any> = [];
                        errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                    });
            }
        }

        // Disabilita i pulsanti nell'header (link alla Library, CM..) in base alle abilitazioni dell'utente
        public hasNotApplicationEnabled = (application: string) => {
            // Se nell'elenco di permessi dell'utente non c'è l'abilitazione da me richiesta, disabilito il pulsante nell'header
            if (this.$scope.globalApplicationData.applicationsEnabled.indexOf(application) != -1) {
                return false;
            } else {
                return true;
            }
        }

        // Esegue il logout e porta nella pagina di login
        public logout = () => {
            // Chiamo il Server per verificare se ho già aperto e concluso il Tutorial   
            this.UserService.logout.get().$promise
                .then((data: SenecaResponse<any>) => {
                    // Se c'è segnalo l'errore
                    if (data.error) {
                        // Dati non validi, quindi alzo l'errore
                        this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                    } else {
                        // Rimuovo il token dalla sessione locale del browser
                        this.$scope.cleanJWT();
                        // Rimuovo le notifiche
                        this.$scope.notificationCount = 0;
                        //Svuoto i contatori
                        this.$scope.bgacademyIndicators = null;
                        // e torno nella pagina di login
                        this.$state.go('app.login');
                        // Chiudo la barra
                        this.$scope.isExpanced = false;
                    }
                })
                .catch((error: Error) => {
                    var errors: Array<any> = [];
                    errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                    this.$rootScope.$emit("showApplicationModalErrors", errors);
                });
        }

        // Apertura Sidenav di sinistra
        public openLeftMenu = () => {
            this.$mdSidenav('left').toggle();
        }

        // Gestisce l'espansione dell'header
        public expandMainHeader = () => {
            if (this.$scope.isExpanced == true) {
                this.$scope.isExpanced = false;
            } else {
                this.$scope.isExpanced = true;
            }
        }

        // Apertura Sidenav del Diario
        public openDiaryMenu = () => {
            this.$mdSidenav('right').toggle();
        }

        // Porta nel Login
        public goToLogin = () => {
            this.$state.go('app.login');
        }

        // Porta nella home page
        public goToHome = () => {
            this.$state.go('app.bgacademyApp.home', {}, { reload: true });
        }

        // Porta alle Impostazioni
        public goToSettings = () => {
            this.$state.go('app.bgacademyApp.settings');
        }

        // Porta nella home page admin
        public goToHomeAdmin = () => {
            this.$state.go('app.bgacademyApp.homeAdmin', {}, { reload: true });
        }

        // Porta alla pagina di monitoraggio
        public goToMonitoring = () => {
            this.$state.go('app.bgacademyApp.monitoringAdmin.users');
        }

        /* Cerco le notifiche da mostrare nell'header, utilizzando un polling continuo
        private getNotificationList(_self: any) {
            if (_self.$scope.globalApplicationData.identityToken) {
                if (_self.$scope.notificationTimeout) {
                    _self.$timeout.cancel(_self.$scope.notificationTimeout);
                }
                _self.$scope.notificationTimeout = null;
                _self.NotificationService.getNotifications.query({
                    fromRecord: 0,
                    numRecords: 1000,
                    getOnlyUnread: true
                }).$promise
                    .then((res: SenecaResponse<any>) => {
                        if (res.response) {
                            _self.$scope.notificationList = res.response.notifications;
                        }
                        _self.$scope.notificationTimeout = _self.$timeout(_self.getNotificationList, 30000, true, _self);
                    })
                    .catch((error: Error) => {
                        // In caso di errore non mostro niente, ma continuo il polling
                        _self.$scope.notificationTimeout = _self.$timeout(_self.getNotificationList, 30000, true, _self);
                    });
            }
        } */

        /* Cerco i contatori da mostrare nei vari banner, utilizzando un polling continuo
        private getBgacademyIndicators(_self: any) {
            if (_self.$scope.globalApplicationData.identityToken) {
                if (_self.$scope.bgacademyIndicatorsTimeout) {
                    _self.$timeout.cancel(_self.$scope.bgacademyIndicatorsTimeout);
                }
                _self.$scope.bgacademyIndicatorsTimeout = null;
                _self.UtilService.getAllCounters.query({
                    newsIgnoreVisibility: false,
                }).$promise
                    .then((senecaResponse: SenecaResponse<any>) => {
                        if (senecaResponse.response) {
                            // Non posso sostituire l'oggetto, perché viene mappato in binding, quindi cancello tutti gli attributi e li ricreo
                            for (let key in _self.$scope.bgacademyIndicators) {
                                if (_self.$scope.bgacademyIndicators.hasOwnProperty(key)) {
                                    delete _self.$scope.bgacademyIndicators[key];
                                }
                            }
                            for (let key in senecaResponse.response) {
                                if (senecaResponse.response.hasOwnProperty(key)) {
                                    _self.$scope.bgacademyIndicators[key] = senecaResponse.response[key];
                                }
                            }
                        }
                        _self.$scope.bgacademyIndicatorsTimeout = _self.$timeout(_self.getBgacademyIndicators, 60000, true, _self);
                    })
                    .catch((error: Error) => {
                        // In caso di errore non mostro niente, ma continuo il polling
                        _self.$scope.bgacademyIndicatorsTimeout = _self.$timeout(_self.getBgacademyIndicators, 60000, true, _self);
                    });
            }
        }*/

        public getExternalResourceUrl = (requestUrl: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/proxy-url?requestUrl=' + encodeURIComponent(requestUrl);
        }

        public getExternalResourceName = (requestUrl: string, requestName: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/proxy-url/' + requestName.replace(/[^a-z0-9\.]/gi, '_') + '?requestUrl=' + encodeURIComponent(requestUrl);
        }

        public getDownloadTempFileUrl = (filename: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/download-temp-file?filename=' + filename;
        }

        public getDownloadPersistentFile = (filename: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/download-persistent-file?filename=' + filename;
        }

        public getDownloadRendiconto2019File = (filename: string) => {
            // Uso un tinytoken invece del token che ho perché BG ne ha di troppo lunghi che potrebbero far scoppiare qualche browser (com'è successo)
            // Per semplicità, visto che sarà usato una tantum e per breve tempo, genero un nuovo tiny ogni volta.
            return this.UserService.renewToken.query({
                tiny: true
            })
                .$promise
                .then((data: SenecaResponse<string>) => {
                    return this.GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/download-rendiconto-2019?token=' + encodeURIComponent(data && data.response) +'&filename=' + filename;
                })
        }

        public getAvatarUrl = (user: any, enableProxyUrl: boolean) => {
            let imgUrl = "";
            if (user) {
                let avatarUrl = user.avatarImage || (user.userOptions && user.userOptions.avatarImage);
                if (avatarUrl && enableProxyUrl) {
                    imgUrl = this.getExternalResourceUrl(avatarUrl);
                } else {
                    imgUrl = user.chiaveSesso == this.BgacademyApplicationData.constants.MALE ? 'assets/img/placeholder.png' : 'assets/img/placeholder_female.png';
                }
            }
            return imgUrl;
        }

        public getDateFromSessionStorage = (keyvalue: string) => {
            if (sessionStorage.getItem(keyvalue)) {
                return new Date(parseInt(sessionStorage.getItem(keyvalue), 10));
            }
            return null;
        }

        public generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
    }

    app.controller("CommonServicesController", CommonServices.CommonServicesController);
}